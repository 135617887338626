import {
  useState,
  useContext,
  useEffect,
  createContext,
  ReactNode,
  useMemo,
} from 'react';

import { routes } from '../routes';
import { accountService } from '../services';
import { Spinner } from '../components/Common';
import {
  AppRouteType,
  DynamicObject,
  ClientAccount,
  SessionData,
} from '../models';
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

function useMergeState<Payload>(
  initialState: Payload
): [state: Payload, setMergedState: Function] {
  const [state, setState] = useState<Payload>(initialState);
  const setMergedState = (newState: DynamicObject<any>) =>
    setState((prevState) => ({ ...prevState, ...newState }));
  return [state, setMergedState];
}

interface AuthContextType {
  loading: boolean;
  clientId: string | number | undefined;
  phone?: string;
  profile: null | ClientAccount;
  isAuth: boolean;
  getProfile: () => void;
}

const AuthContext = createContext<AuthContextType>({
  loading: true,
  clientId: undefined,
  phone: undefined,
  profile: null,
  isAuth: false,
  getProfile: () => {},
});

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const sessionData: SessionData = useMemo(() => {
    const craftmen = sessionStorage.getItem('craftmen');
    if (craftmen) {
      return JSON.parse(craftmen);
    }
    return null;
  }, []);

  const [userData, setUserData] = useMergeState({
    loading: true,
    clientId: searchParams.get('clientId') || sessionData?.clientId,
    address: searchParams.get('address') || sessionData?.address,
    phone: searchParams.get('phone') || sessionData?.phone,
    isAuth: false,
    profile: null,
  });

  const currentPage = routes?.find((_: AppRouteType) =>
    matchPath(_.path, location.pathname) ? true : false
  );

  const getProfile = async () => {
    try {
      setUserData({ loading: true, profile: null });
      const { craftmen } = await accountService.profile({
        clientId: userData.clientId,
        address: userData.address,
        phone: userData.phone,
      });
      sessionStorage.setItem(
        'craftmen',
        JSON.stringify({
          clientId: userData.clientId,
          address: userData.address,
          phone: userData.phone,
        })
      );
      searchParams.delete('clientId');
      searchParams.delete('address');
      searchParams.delete('phone');
      setSearchParams(searchParams);
      setUserData({
        loading: false,
        profile: craftmen,
        isAuth: true,
      });
    } catch (error) {
      setUserData({ loading: false, clientId: null });
      navigate('/404', { replace: true });
    }
  };

  useEffect(() => {
    if (!currentPage) {
      navigate('/404', { replace: true });
    }
    if (!currentPage?.auth) {
      setUserData({ loading: false });
    }

    if (!userData?.clientId && currentPage?.auth) {
      setUserData({ loading: false });
      navigate('/404', { replace: true }); // If not authenticated, redirect to 404 page
    }
    if (userData?.clientId && !userData.profile) {
      sessionStorage.removeItem('craftmen');
      // setAxiosToken(userData?.clientId);
      getProfile();
    }
  }, [userData?.clientId, currentPage]);

  return (
    <AuthContext.Provider
      value={{
        getProfile,
        loading: userData.loading,
        clientId: userData.clientId,
        phone: userData.phone,
        profile: userData.profile,
        isAuth: userData.isAuth,
      }}
    >
      {userData?.loading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
}

const useAuthContext = () => useContext(AuthContext);

export default useAuthContext;
