const Icon3Sur3 = () => {
  return (
    <svg id="_3sur3" data-name="3sur3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="47.995" height="48" viewBox="0 0 47.995 48">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#99c81c" />
          <stop offset="1" stopColor="#bde05d" />
        </linearGradient>
      </defs>
      <g id="Groupe_1372" data-name="Groupe 1372">
        <path id="Tracé_4232" data-name="Tracé 4232" d="M24.414,107.4A18.773,18.773,0,1,1,5.642,126.178,18.793,18.793,0,0,1,24.414,107.4m0-5.227a24,24,0,1,0,24,24,24,24,0,0,0-24-24" transform="translate(-0.416 -102.178)" fill="url(#linear-gradient)" />
      </g>
      <text id="_3_sur_3" data-name="3 sur 3" transform="translate(24.041 27.005)" fill="#4a4b4f" fontSize="10" fontWeight="500">
        <tspan y="0" x="-15">3</tspan>
        <tspan y="0" x="-8" xmlSpace="preserve">sur</tspan>
        <tspan y="0" x="8">3</tspan>
      </text>
    </svg>
  );
};

export default Icon3Sur3;
