import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';
import { Loading } from '../../models';

interface AccountState {
  selectForgetPasswordLoading: Loading;
}

const name = 'account';
const initialState: AccountState = {
  selectForgetPasswordLoading: 'idle',
};

export const accountSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export const selectForgetPasswordLoading = (state: RootState) =>
  state.account.selectForgetPasswordLoading;

export default accountSlice.reducer;
