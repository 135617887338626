import { useState, useEffect, useMemo } from 'react';
import { Menu, Popover } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { IconBurger, IconCrossMenu } from '../../Icons';
import Logo from '../../../assets/images/logo-ecodrop.png';
import SmallLogo from '../../../assets/images/logo-ecodrop-small.png';
import useAuthContext from '../../../store/auth-context';

const Header = ({
  onVisibleDrawerChange,
}: {
  onVisibleDrawerChange: Function;
}) => {
  const location = useLocation();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const { profile } = useAuthContext();
  const fullName = useMemo(
    () => [profile?.first_name, profile?.last_name].filter((i) => i).join(' '),
    [profile]
  );
  useEffect(() => {
    onVisibleDrawerChange(visibleDrawer);
  }, [onVisibleDrawerChange, visibleDrawer]);

  const menuVertical = (
    <div className="header-popover">
      <Menu
        selectedKeys={[location.pathname]}
        mode="inline"
        className="header-navbar"
      >
        <Menu.Item key="/">
          <p className="user-name">{profile?.name}</p>
        </Menu.Item>
        <Menu.Item key="contact">
          <div className="flex-justify-between align-center flex-wrap">
            {'Contacter Ecodrop'}&nbsp;&nbsp; &gt;{' '}&nbsp;
            <a href="tel:0173722480" className="tel-contact">
              01 73 72 24 80
            </a>
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <div className="header__wrapper">
      <header className="header">
        <Link to="/">
          <div className="d-flex">
            <img
              src={Logo}
              className="logo"
              alt="Service déchèterie en agence"
              width={90}
              height={40}
            />
            <img
              src={SmallLogo}
              className="responsive-logo"
              alt="Service déchèterie en agence"
              width={90}
              height={40}
            />
          </div>
        </Link>
        <Popover
          trigger="click"
          placement="bottomRight"
          open={visibleDrawer}
          content={menuVertical}
          onOpenChange={setVisibleDrawer}
          id="header-popover"
        >
          <a className="header__menu-button">
            {visibleDrawer ? <IconCrossMenu /> : <IconBurger />}
          </a>
        </Popover>
        <div className="contact-info-container">
          <a href='tel:0173722480' className="tel-link">
            <div className="contact-phone" />
            01 73 72 24 80
          </a>
        </div>
      </header>
      <div className="container-info-user">
        <span className="benne-info-user-text">{profile?.name}</span>
        <div className="benne-info-user"></div>
      </div>
    </div>
  );
};

export default Header;
