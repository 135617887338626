import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { AppRouteType } from '../models';
import { Spinner } from '../components/Common';
import BasePage from '../components/Layout/BasePage/BasePage';

const Home = lazy(() => import('../pages/Home'));
const Orders = lazy(() => import('../pages/Orders'));
const NotFound = lazy(() => import('../pages/404'));

const waitFor = (Tag: React.LazyExoticComponent<() => JSX.Element | null>) => (
  <Suspense fallback={<Spinner />}>
    <Tag />
  </Suspense>
);

export const routes: AppRouteType[] = [
  {
    name: '404',
    path: '404',
    component: NotFound,
    isPublic: true,
  },
  {
    name: 'List of chaintiers',
    auth: true,
    path: '/',
    component: Home,
  },
  {
    name: 'List of orders',
    auth: true,
    path: '/orders',
    component: Orders,
  },
];

export default function RoutesAppRoutes() {
  // const { accessToken, profile } = useAuthContext();

  // const publicRoutes = routes
  //   .filter((route) => !route.auth || route.isPublic)
  //   .map((route) => (
  //     <Route
  //       key={route.path}
  //       path={route.path}
  //       element={waitFor(route.component)}
  //     />
  //   ));

  // public routes
  // if (!accessToken) return <Routes>{publicRoutes}</Routes>;

  // authenticated routes
  const authenticatedRoutes = routes
    // .filter(
    //   (route) =>
    //     (route.auth && hasPermission(route.roles, profile)) || route.isPublic
    // )
    .map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={waitFor(route.component)}
      />
    ));

  return (
    <BasePage>
      <Routes>{authenticatedRoutes}</Routes>
    </BasePage>
  );
}
