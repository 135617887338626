import moment from "moment";

const Footer = () => {
  const currentYear = moment().year();

  return (
    <div className="footer-page">
      <div className="footer-container">
        <div className="footer-block left-block">
            <a href='https://www.ecodrop.net/cgu' target="_blank" rel="noreferrer">CGU</a>
            <a href="http://www.ecodrop.net/info-legales" target="_blank" rel="noreferrer">INFOS LÉGALES</a>
        </div>
        <div className="footer-block right-block">
            <span>Copyright &copy; Ecodrop 2023 - {currentYear}</span>
        </div>
      </div>
        <div className="space-160" />
    </div>
  );
};

export default Footer;