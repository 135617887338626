const Icon2Sur3 = () => {
  return (
    <svg id="_2sur3" data-name="2sur3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
      <defs>
        <linearGradient id="linear-gradient" x1="0.344" y1="0.444" x2="0.939" y2="0.765" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#99c81c" />
          <stop offset="1" stopColor="#bde05d" />
        </linearGradient>
      </defs>
      <g id="Groupe_1372" data-name="Groupe 1372">
        <path id="Tracé_4232" data-name="Tracé 4232" d="M24.414,107.4A18.773,18.773,0,1,1,5.642,126.178,18.793,18.793,0,0,1,24.414,107.4m0-5.227a24,24,0,1,0,24,24,24,24,0,0,0-24-24" transform="translate(-0.416 -102.178)" fill="#d5d7d6" />
        <path id="Tracé_4233" data-name="Tracé 4233" d="M28.658,150.227a23.813,23.813,0,0,1-17.412-7.466,2.612,2.612,0,0,1,3.791-3.593,18.765,18.765,0,1,0,13.621-31.655,2.609,2.609,0,1,1,0-5.218,23.966,23.966,0,1,1,0,47.933" transform="translate(-4.666 -102.227)" fill="url(#linear-gradient)" />
      </g>
      <text id="_2_sur_3" data-name="2 sur 3" transform="translate(24.041 27.005)" fill="#4a4b4f" font-size="10" font-weight="500">
        <tspan y="0" x="-15">2</tspan>
        <tspan y="0" x="-8" xmlSpace="preserve">sur</tspan>
        <tspan y="0" x="8">3</tspan>
      </text>
    </svg>
  );
};

export default Icon2Sur3;
